import Vue from 'vue'

export default {
  SET (state, payload) {
    state.doctor = Object.assign({}, state.doctor, payload) //precisa ser assim para manter a reatividade
  },
  SET_DOCTORS (state, payload) {
    state.doctors = payload
  },
  SET_DOCTORS_GRID (state, payload) {
    state.doctorsGrid = payload
  },
  STORE (state, payload) {
    state.doctors.push(payload) //precisa ser assim para manter a reatividade
    state.doctors.sort((a, b) => { return a.name.localeCompare(b.name) }) // Ordena por nome

    state.doctorsGrid.data.push(payload)
    state.doctorsGrid.data.sort((a, b) => { return a.name.localeCompare(b.name) }) // Ordena por nome
    state.doctorsGrid.total = state.doctorsGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.doctors.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.doctors, idx, payload)

    const idx1 = state.doctorsGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.doctorsGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.doctors.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.doctors.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.doctorsGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.doctorsGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.doctorsGrid.total = state.doctorsGrid.total - 1
    }
  }
}
