export default {
  doctor: {
    address: {}
  },
  doctors: [],
  doctorsGrid: {
    data: [],
    total: 0
  }
}
