export default {
  get (state) {
    const doctor = JSON.parse(JSON.stringify(state.doctor))
    doctor.address.country = 1 // Brasil

    const sortPhonesBy = ['mobile', 'phone', 'other']
    //AJUSTANDO OS TELEFONES VAZIOS
    if (doctor.phones) {
      if (!doctor.phones.filter(phone => phone.type === 'mobile').length) {
        doctor.phones.unshift({id: null, phone: null, type: 'mobile' })
      }
      if (!doctor.phones.filter(phone => phone.type === 'phone').length) {
        doctor.phones.push({id: null, phone: null, type: 'phone' })
      }
      if (!doctor.phones.filter(phone => phone.type === 'other').length) {
        doctor.phones.push({id: null, phone: null, type: 'other' })
      }
      //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
      doctor.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
    }

    if (doctor.emails && doctor.emails.length) doctor.email = doctor.emails[0].email
    else doctor.email = null
    doctor.cacs = doctor.cacs && doctor.cacs.map(a => a.id)

    return doctor
  },

  forSelect (state) {
    const doctors = []
    for (const k in state.doctors) {
      doctors.push({
        value: state.doctors[k].id,
        label: state.doctors[k].name,
        data: state.doctors[k]
      })
    }
    return doctors
  }
}
